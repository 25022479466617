* {
  box-sizing: border-box;
  cursor: default;
}

html,
body {
  margin: 0;
}
body {
  height: 100vh;
  width: 100vw;
  background: black;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.react-pdf__Page__svg {
  width: 100vw;
  height: auto !important;
}

.react-pdf__message {
  padding: 20px;
  color: white;
}


::backdrop
{
    background-color: rgb(0, 0, 0);
}

:fullscreen::backdrop {
  background-color: rgba(255, 255, 255, 0);
}

#warning-message {
  display: none;
}

@media only screen and (orientation: portrait) {
  .iosOrientation {
    display: none;
  }

  #warning-message {
    display: block;
  }
}

@media only screen and (orientation: landscape) {
  #warning-message {
    display: none;
  }

  .iosOrientation {
    display: block;
    z-index: 9999999999;
  }
}